import React from 'react';
import './comingsoonpage.css';

function ComingSoonPage() {
  return (
    <div className="imageContainer"></div>
  );
}

export default ComingSoonPage;
