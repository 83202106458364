import './App.css';
import ComingSoonPage from './coming-soon/CommingSoonPage';

function App() {
  return (
    <div>
      <ComingSoonPage />
    </div>
  );
}

export default App;
